<template>
  <el-table :data="listPages">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="" prop="" width="40">
      <template slot-scope="scope">
        <i
          v-if="scope.row.banner_type && scope.row.banner_type == 'private'"
          class="fas fa-lock"
        ></i>
      </template>
    </el-table-column>
    <el-table-column label="Tên trang" prop="name" min-width="150">
      <template slot-scope="scope">
        <router-link class="text-primary" :to="{ name: 'PagesInfo', params: { id: scope.row.id } }">{{ scope.row.label }}</router-link>
      </template>
    </el-table-column>
    <el-table-column label="Mô tả" min-width="150">
      <template slot-scope="scope">
        <span>{{ scope.row.description }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Link truy cập" min-width="150">
      <template slot-scope="scope">
        <a class="text-primary" :href="scope.row.link" target="_blank">{{ scope.row.link }}</a>
      </template>
    </el-table-column>
    <el-table-column label="Tổ chức quản lý" min-width="150">
      <template slot-scope="scope">
        <p v-if="scope.row.organization">{{ scope.row.organization.summary }}</p>
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="200" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Nội dung hiển thị" placement="top">
          <el-button icon="el-icon-document" type="primary" circle size="small" :disabled="scope.row.meta.positions.length === 0" @click="$router.push({ name: 'PagesDetail', params: { id: scope.row.id } })"></el-button>
        </el-tooltip>
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button icon="el-icon-edit" type="primary" circle size="small" @click="$router.push({ name: 'PagesInfo', params: { id: scope.row.id } })"></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button icon="el-icon-delete" type="danger" circle size="small" @click="handleDeletePage(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { deleteBanner } from '@/services/banner'
export default {
  name: 'ListPageTable',
  props: {
    listPages: Array
  },
  methods: {
    handleUpdatePage(row) {
      this.$emit('updatePage', row)
    },

    handleDeletePage(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa trang này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(async () => {
        await deleteBanner(row.id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa trang thành công thành công'
          })
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Xóa trang thất bại'
          })
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.price {
  p {
    display: inline-block;
    margin: 5px 0;
    background-color: #e0e0e0;
    padding: 5px 10px;
  }
}
</style>
